<div class="projects pb-5">
    <div class="scrolling-wrapper">
        <!-- <div class="project" style="background-image: url('../../../assets/projects/vondreii.PNG'); background-size:cover"> -->
        <div class="project">
            <h2>Project1</h2>
        </div>
        <div class="project"><h2>Project2</h2></div>
        <div class="project"><h2>Project3</h2></div>
        <div class="project"><h2>Project4</h2></div>
        <div class="project"><h2>Project5</h2></div>
        <div class="project"><h2>Project6</h2></div>
    </div>
    <div class="viewPortfolio mt-5">
        <button class="action-button"><strong>VIEW OUR PORTFOLIO</strong></button>
    </div>
</div>