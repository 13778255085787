import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from "@angular/fire";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { environment } from "../environments/environment";

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HomeComponent } from './home/home.component';
import { HomeTeamComponent } from './home/home-team/home-team.component';
import { HomeServicesComponent } from './home/home-services/home-services.component';
import { HomeHeaderComponent } from './home/home-header/home-header.component';
import { HomeIntroComponent } from './home/home-intro/home-intro.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { HomeProjectsComponent } from './home/home-projects/home-projects.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeTeamComponent,
    HomeServicesComponent,
    HomeHeaderComponent,
    HomeIntroComponent,
    NavbarComponent,
    HomeProjectsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
