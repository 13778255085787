<div class="header">
    <div class="rows">
      <div class="column-2 pl-5 m-0 intro-block">
        <h5 class="padding-l">Web Design <br>& Development</h5>
        <p class="padding-l padding-r">
            We provide fast web design and development solutions for small businesses. 
            <br><br>
            <button class="action-button"><strong>GET A QUOTE</strong></button>
        </p>
      </div>
      <div class="column-2 m-0 image-block">
        <img src="../../../assets/home/computer.png" alt="">
      </div>
    </div>
</div>
