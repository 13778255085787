<div class="services">
    <div class="row text-center">
        <h2 class="mx-auto mt-5 p-4">Our Services</h2>
    <!-- Adaptable text -->
        <div class="row">
            <div class="col m-4 p-4">
                <img src="../../../assets/services/placeholder.jpg" alt="" class="rounded-circle w-25">
                <h5 class="mt-3">ADAPTABLE</h5>
                <p class="p-4 mx-5">What is adaptability? - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem perspiciatis voluptatum a</p>
            </div>
            <!-- Customizable text -->
            <div class="col m-4 p-4">
                <img src="../../../assets/services/placeholder.jpg" alt="" class="rounded-circle w-25">
                <h5 class="mt-3">CUSTOMIZABLE</h5>
                <p class="p-4 mx-5">Something about how they can change their design and customise it - Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
            </div>
            <!-- Portable text -->
            <div class="col m-4 p-4">
                <img src="../../../assets/services/placeholder.jpg" alt="" class="rounded-circle w-25">
                <h5 class="mt-3">PORTABLE</h5>
                <p class="p-4 mx-5">Something about being able to use multiple devices - Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
            </div>
        </div>
    </div>
</div>