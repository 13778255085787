<div class="team">
    <div class="row text-center">
        <h2 class="mx-auto mt-5 p-4">Our Team</h2>
        <div class="row">
            <div class="col m-4 p-4">
                <!-- <div class="row"> -->
                    <img src="../../../assets/team/shay.jpg" alt="" class="rounded-circle w-25">
                <!-- </div> -->
                <h5 class="mt-3">SHARLENE VON DREHNEN</h5>
                <p class="p-4 mx-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem perspiciatis voluptatum a</p>
            </div>
            <div class="col m-4 p-4">
                <!-- <div class="row"> -->
                    <img src="../../../assets/team/sora.jpg" alt="" class="rounded-circle w-25">
                <!-- </div> -->
                <h5 class="mt-3">SORA KHAN</h5>
                <p class="p-4 mx-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem perspiciatis voluptatum a</p>
            </div>
        </div>
    </div>
</div>