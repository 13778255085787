<div class="header">
    <div class="intro-section" style="background-image: url('../../../assets/home/header.png');">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-7 mx-auto" data-aos="fade-up">
            <h1>Web Design & Development</h1>
            <p>We provide fast web design and development solutions for small businesses.</p>
            <button class="action-button"><strong>GET A QUOTE</strong></button>
          </div>
        </div>
      </div>
    </div>
  </div>