<!-- These are the components that might be deleted -->
<!-- <app-navbar></app-navbar> -->
<!-- <app-home-header></app-home-header>  -->
<!-- <app-home-intro></app-home-intro> -->
<!-- <app-home-services></app-home-services> -->
<!-- <app-home-team></app-home-team> -->
<!-- <app-home-projects></app-home-projects> -->

<div class="home-container">
    <div class="content">
        <img src="../../../assets/logo/logo.svg" width="200px">
        <p>We are currently working on something . . .</p>
    </div>
</div>

